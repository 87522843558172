<template>
   <div class="flex">
      <div
         class="pb-20 mr-50 paneText"
         v-for="(item, index) of paneList"
         :key="index"
         @click="changeTab(item.label)"
         :class="openSearchFlag == item.name ? 'pitchOn' : 'NopitchOn'"
      >
         {{ item.label }}
      </div>
   </div>
</template>
<script>
export default {
    props: {
      openSearchFlag: {
         type: Number,
         default: 0,
      },
   },
   data() {
      return {
        //  openSearchFlag: "0",
         paneList: [
            {
               label: "求购列表数据",
               name: "0",
            },
            {
               label: "求购统计数据",
               name: "1",
            },
            {
               label: "用户数据",
               name: "2",
            },
            {
               label: "统计付费数据",
               name: "3",
            },
         ],
      };
   },
   computed: {},
   created() {},
   methods: {
      changeTab(label) {
         this.$removeStorage("fetchDataPage");
         this.$removeStorage("fetchDataSize");
         switch (label) {
            case "求购列表数据":
               this.$router.push({
                  name: "dataManagement",
               });
               break;
            case "求购统计数据":
               this.$router.push({
                  name: "dataStatistics",
               });
               break;
            case "用户数据":
               this.$router.push({
                  name: "UserData",
               });
               break;
            case "企业数据":
               this.$router.push({
                  name: "EnterprisesData",
               });
               break;
            case "统计付费数据":
               this.$router.push({
                  name: "StatisticalpaymentData",
               });
               break;
            // StatisticalpaymentData
         }
      },
   },
};
</script>
<style lang="less" scoped>
.paneText {
   font-size: 24px;
   font-weight: 500;
   color: #333333;
   cursor: pointer;
   margin-right: 50px;
   padding-bottom: 20px;
}
.pitchOn {
   border-bottom: 3px solid #c51d25;
   color: #c51d25;
   cursor: pointer;
}

.NopitchOn {
   color: #333333;
   cursor: pointer;
}
</style>