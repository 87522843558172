<template>
  <div class="home">
    <NavHeader></NavHeader>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="container"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" class="container"></router-view>
    <!--  -->
  </div>
</template>
<script>
import NavHeader from '@/components/NavHeader.vue';
export default {
  components: {
    NavHeader,
  },
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.home {
  min-width: 1280px;
  background: #FFFFFF;

  .container {
    width: 1280px;
    padding: 80px 0 30px;
    margin: auto;
    box-sizing: border-box;
    overflow: hidden;
  }
}
</style>