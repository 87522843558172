import { apiPost } from "./service";
const http = {
    // 退出登陆
    logout(params) {
        return apiPost('/internal/users/logout', params)
    },
    // 1.13 retrievePassword 找回密码接口
    retrievePassword(params) {
        return apiPost('/internal/users/retrievePassword', params)
    },
    // token刷新
    refreshToken(params) {
        return apiPost('/internalusers/users/refreshToken', params)
    },
    //   1.3 getInternalUserInfo 个人信息展示
    getInternalUserInfo(params) {
        return apiPost('/internal/users/getInternalUserInfo', params)
    },
    //   1.11 findSearchInternalUserDetails 搜索查询工员列表详情接口
    findSearchInternalUserDetails(params) {
        return apiPost('/internal/users/findSearchInternalUserDetails', params)
    },

    // 7.6 dataUserFindEnterpriseStats 用户查看企业数据统计接口
    dataUserFindEnterpriseStats(params) {
        return apiPost('/enterprise/dataUserFindEnterpriseStats', params)
    },
    // 7.7 dataUserFindEnterpriseStatsList 用户查看企数据统计浏览转发联系收藏列表接口
    dataUserFindEnterpriseStatsList(params) {
        return apiPost('/enterprise/dataUserFindEnterpriseStatsList', params)
    },
    // 7.8 dataUserFindEnterpriseListProductTypeStats 用户查看企数产品类型列表统计接口
    dataUserFindEnterpriseListProductTypeStats(params) {
        return apiPost('/enterprise/dataUserFindEnterpriseListProductTypeStats', params)
    },

    //   11.4 dataUserSearchList 用户统计数据列表
    dataUserSearchList(params) {
        return apiPost('/user/dataUserSearchList', params)
    },

    //   11.5 dataUserSearchListDetails 用户统计数据列表详情
    dataUserSearchListDetails(params) {
        return apiPost('/user/dataUserSearchListDetails', params)
    },

    //   11.6 dataUserStats 用户统计数据接口
    dataUserStats(params) {
        return apiPost('/user/dataUserStats', params)
    },


    //   14.1 dataProductTypeList 产品类型列表接口
    dataProductTypeList(params) {
        return apiPost('/product-type/dataProductTypeList', params)
    },

    //   15.1 dataForumBuyList 供应求购帖子列表接口
    dataForumBuyList(params) {
        return apiPost('/forum-buy/dataForumBuyList', params)
    },

    //   15.2 dataForumBuyListDetails 供应求购帖子列表详情接口
    dataForumBuyListDetails(params) {
        return apiPost('/forum-buy/dataForumBuyListDetails', params)
    },
    //   15.3 dataForumBuyDetailsUserList 供应求购帖子列表详情用户列表接口
    dataForumBuyDetailsUserList(params) {
        return apiPost('/forum-buy/dataForumBuyDetailsUserList', params)
    },
    //   15.4 dataForumBuyStats 供应求购帖子数据统计接口
    dataForumBuyStats(params) {
        return apiPost('/forum-buy/dataForumBuyStats', params)
    },
    //   15.5 dataUserForumBuyStats 用户供应求购帖子数据统计接口
    dataUserForumBuyStats(params) {
        return apiPost('/forum-buy/dataUserForumBuyStats', params)
    },

    //   15.6 dataUserForumBuyStatsList 用户供应求购帖子数据统计发帖浏览转发联系列表接口
    dataUserForumBuyStatsList(params) {
        return apiPost('/forum-buy/dataUserForumBuyStatsList', params)
    },

    // 16.1 dataUserProductStats 用户商品数据统计接口
    dataUserProductStats(params) {
        return apiPost('/product/dataUserProductStats', params)
    },

    // 16.3 dataUserProductListTypeStats 用户商品数据列表类型统计接口
    dataUserProductListTypeStats(params) {
        return apiPost('/product/dataUserProductListTypeStats', params)
    },
    // 16.2 dataUserProductStatsList 用户商品数据统计浏览转发收藏列
    dataUserProductStatsList(params) {
        return apiPost('/product/dataUserProductStatsList', params)
    },

    // 查询企业会员信息列表
    queryEnterpriseVips(params) {
        return apiPost('/enterprise-vip/queryEnterpriseVips', params)
    },
    // 修改企业会员信息
    updateEnterpriseVip(params) {
        return apiPost('/enterprise-vip/updateEnterpriseVip', params)
    },

     // 查询企业/个人信息及权益
     queryEnterpriseOrUserWithBenefits(params) {
        return apiPost('/maintenance/queryEnterpriseOrUserWithBenefits', params)
    },
    // 查询企业/个人权益详情列表
    queryBenefitsDetails(params) {
        return apiPost('/maintenance/queryBenefitsDetails', params)
    },
    // 授权（发放）企业/个人权益
    grantBenefitToEntOrUsr(params) {
        return apiPost('/maintenance/grantBenefitToEntOrUsr', params)
    },
    // 首页公告内容
    queryAnnouncement(params) {
        return apiPost('/maintenance/queryAnnouncement', params)
    },
    // 更新首页公告内容
    updateAnnouncement(params) {
        return apiPost('/maintenance/updateAnnouncement', params)
    },
    // 查询广告轮播图管理列表
    querySlideshowList(params) {
        return apiPost('/maintenance/querySlideshowList', params)
    },
    // 上传广告轮播图
    uploadSlideshow(params) {
        return apiPost('/maintenance/uploadSlideshow', params)
    },
    // 查询广告轮播图绑定企业/产品
    queryBindEntOrPro(params) {
        return apiPost('/maintenance/queryBindEntOrPro', params)
    },
    // 更新广告轮播图/产品
    updateSlideshowList(params) {
        return apiPost('/maintenance/updateSlideshowList', params)
    },
    // 查询用户平台认证身份信息
    queryUserWithRecognition(params) {
        return apiPost('/maintenance/queryUserWithRecognition', params)
    },
    // 更新用户身份平台认证
    updateUserRecognition(params) {
        return apiPost('/maintenance/updateUserRecognition', params)
    },
    // 查询明星店铺信息
    queryStarEnterprise(params) {
        return apiPost('/maintenance/queryStarEnterprise', params)
    },
    // 更新明星店铺
    updateStarEnterprise(params) {
        return apiPost('/maintenance/updateStarEnterprise', params)
    },
    // 查询管理员用户信息
    queryUserMaster(params) {
        return apiPost('/maintenance/queryUserMaster', params)
    },
    // 更新管理员用户配置
    updateUserMaster(params) {
        return apiPost('/maintenance/updateUserMaster', params)
    },
    // 查询统计付费信息
    queryStatisticPaymentSituation(params) {
        return apiPost('/maintenance/queryStatisticPaymentSituation', params)
    },


    // 4.1 findEnterpriseAudit 员工查询企业审核列表接口
    findEnterpriseAudit(params) {
        return apiPost('/enterprise-audit/findEnterpriseAudit', params)
    },
    // 4.2 findEnterpriseAuditDetails 员工查询企业审核详情接口
    findEnterpriseAuditDetails(params) {
        return apiPost('/enterprise-audit/findEnterpriseAuditDetails', params)
    },
    // 4.3 approveEnterpriseAudit 员工审批企业审核接口
    approveEnterpriseAudit(params) {
        return apiPost('/enterprise-audit/approveEnterpriseAudit', params)
    },
    // 5.1 findMediumAuditList 查询媒体审核列表接口
    findMediumAuditList(params) {
        return apiPost('/medium-audit/findMediumAuditList', params)
    },
    // 5.2 findMediumAuditListDetails 查询媒体审核列表详情接口
    findMediumAuditListDetails(params) {
        return apiPost('/medium-audit/findMediumAuditListDetails', params)
    },
    // 5.3 approveMediumAudit 媒体审核接口
    approveMediumAudit(params) {
        return apiPost('/medium-audit/approveMediumAudit', params)
    },
}

export { http }