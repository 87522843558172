// 个人信息
export default [{
    path: '/navIndex',
    name: 'navIndex',
    redirect: '/navIndex',
    component: () => import('@/pages/Home'),
    children: [
        {
            path: '/navIndex',
            name: 'navIndex',
            component: () => import('@/pages/nav/index'),
            redirect: '/navIndex/workCard',
            children: [
                {
                    path: '/navIndex/workCard',
                    name: 'workCard',
                    component: () => import('@/pages/nav/workCard'),
                },
                {
                    path: '/navIndex/dataManagement',
                    name: 'dataManagement',
                    component: () => import('@/pages/nav/dataManagement'),
                    meta: { keepAlive: true }
                },
                {
                    path: '/navIndex/dataManagementDetail',
                    name: 'dataManagementDetail',
                    component: () => import('@/pages/nav/dataManagementDetail'),
                },
                {
                    path: '/navIndex/dataManagmentDetailsUserList',
                    name: 'dataManagmentDetailsUserList',
                    component: () => import('@/pages/nav/dataManagmentDetailsUserList'),
                },

                {
                    path: '/navIndex/dataStatistics',
                    name: 'dataStatistics',
                    component: () => import('@/pages/nav/dataStatistics'),
                },
                // dataStatisticsList
                {
                    path: '/navIndex/dataStatisticsList',
                    name: 'dataStatisticsList',
                    component: () => import('@/pages/nav/dataStatisticsList'),
                },
                // dataStatisticsDetailsUserList
                {
                    path: '/navIndex/dataStatisticsDetailsUserList',
                    name: 'dataStatisticsDetailsUserList',
                    component: () => import('@/pages/nav/dataStatisticsDetailsUserList'),
                },
                {
                    path: '/navIndex/UserData',
                    name: 'UserData',
                    component: () => import('@/pages/nav/UserData/UserData'),
                },
                {
                    path: '/navIndex/Newadduserlist',
                    name: 'Newadduserlist',
                    component: () => import('@/pages/nav/UserData/Newadduserlist'),
                    meta: { keepAlive: true }
                },
                {
                    path: '/navIndex/UsersList',
                    name: 'UsersList',
                    component: () => import('@/pages/nav/UserData/UsersList'),
                    meta: { keepAlive: true }
                },
                {
                    path: '/navIndex/UserDetail',
                    name: 'UserDetail',
                    component: () => import('@/pages/nav/UserData/UserDetail'),
                },
                {
                    path: '/navIndex/productDataDetail',
                    name: 'productDataDetail',
                    component: () => import('@/pages/nav/UserData/productDataDetail'),
                },
                {
                    path: '/navIndex/enterpriseDataDetail',
                    name: 'enterpriseDataDetail',
                    component: () => import('@/pages/nav/UserData/enterpriseDataDetail'),
                },
                {
                    path: '/navIndex/PostDataDetail',
                    name: 'PostDataDetail',
                    component: () => import('@/pages/nav/UserData/PostDataDetail'),
                },
                {
                    path: '/navIndex/EnterprisesData',
                    name: 'EnterprisesData',
                    component: () => import('@/pages/nav/enterprisesData'),
                },
                {
                    path: '/navIndex/StatisticalpaymentData',
                    name: 'StatisticalpaymentData',
                    component: () => import('@/pages/nav/StatisticalpaymentData'),
                },
                {
                    path: '/navIndex/Modifymember',
                    name: 'Modifymember',
                    component: () => import('@/pages/nav/Comprehensive/Modifymember'),
                },
                {
                    path: '/navIndex/Modifycarousel',
                    name: 'Modifycarousel',
                    component: () => import('@/pages/nav/Comprehensive/Modifycarousel'),
                },
                {
                    path: '/navIndex/Givetrafficpackages',
                    name: 'Givetrafficpackages',
                    component: () => import('@/pages/nav/Comprehensive/Givetrafficpackages'),
                },
                {
                    path: '/navIndex/AmendmentAnnouncement',
                    name: 'AmendmentAnnouncement',
                    component: () => import('@/pages/nav/Comprehensive/AmendmentAnnouncement'),
                },
                {
                    path: '/navIndex/Platformcertification',
                    name: 'Platformcertification',
                    component: () => import('@/pages/nav/Comprehensive/Platformcertification'),
                },
                {
                    path: '/navIndex/ModifyStarStores',
                    name: 'ModifyStarStores',
                    component: () => import('@/pages/nav/Comprehensive/ModifyStarStores'),
                },
                {
                    path: '/navIndex/ConfigurationAdministrator',
                    name: 'ConfigurationAdministrator',
                    component: () => import('@/pages/nav/Comprehensive/ConfigurationAdministrator'),
                },
                // 
                {
                    path: '/navIndex/Auditmanagement',
                    name: 'Auditmanagement',
                    component: () => import('@/pages/nav/Settle/Auditmanagement'),
                },
            ]
        },

    ]
}]