<template>
   <div class="flex">
      <div
         class="pb-20 mr-50 paneText"
         v-for="(item, index) of paneList"
         :key="index"
         @click="changeTab(item.label)"
         :class="openSearchFlag == item.name ? 'pitchOn' : 'NopitchOn'"
      >
         {{ item.label }}
      </div>
   </div>
</template>
<script>
export default {
   props: {
      openSearchFlag: {
         type: Number,
         default: 0,
      },
   },
   data() {
      return {
         paneList: [
            {
               label: "修改会员等级",
               name: "0",
            },
            {
               label: "修改轮播图",
               name: "1",
            },
            {
               label: "赠送流量包次数",
               name: "2",
            },
            {
               label: "修改公告",
               name: "3",
            },
            {
               label: "平台认证管理",
               name: "4",
            },
            {
               label: "修改明星店铺",
               name: "5",
            },
            {
               label: "配置管理员",
               name: "6",
            },
         ],
      };
   },
   computed: {},
   created() {},
   methods: {
      changeTab(label) {
         this.$removeStorage("fetchDataPage");
         this.$removeStorage("fetchDataSize");
         switch (label) {
            case "修改会员等级":
               this.$router.push({
                  name: "Modifymember",
               });
               break;
            case "修改轮播图":
               this.$router.push({
                  name: "Modifycarousel",
               });
               break;
            case "赠送流量包次数":
               this.$router.push({
                  name: "Givetrafficpackages",
               });
               break;
            case "修改公告":
               this.$router.push({
                  name: "AmendmentAnnouncement",
               });
               break;
            case "平台认证管理":
               this.$router.push({
                  name: "Platformcertification",
               });
               break;
            case "修改明星店铺":
               this.$router.push({
                  name: "ModifyStarStores",
               });
               break;
            case "配置管理员":
               this.$router.push({
                  name: "ConfigurationAdministrator",
               });
               break;
         }
      },
   },
};
</script>
<style lang="less" scoped>
.paneText {
   font-size: 20px;
   font-weight: 500;
   color: #333333;
   cursor: pointer;
   margin-right: 10px;
   padding-bottom: 10px;
}
.pitchOn {
   border-bottom: 3px solid #c51d25;
   color: #c51d25;
   cursor: pointer;
}

.NopitchOn {
   color: #333333;
   cursor: pointer;
}
</style>